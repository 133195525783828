<!-- eslint-disable vue/no-v-html -->
<template>
	<w-layout fill-height class="pt-2 pb-4" column>
		<w-flex v-if="step == 1" shrink pb-4 px-4>
			<v-text-field v-model="search" hide-details single-line clearable :label="$t('actions.search')" prepend-inner-icon="search"></v-text-field>
		</w-flex>
		<w-flex shrink fill-height scroll-y row px-4>
			<template v-if="step == 1">
				<h2 class="title mt-3">{{ $t('service-requests.step1.title') }}</h2>
				<p class="body-1 mt-2">{{ $t('service-requests.step1.description') }}</p>
				<v-card v-if="loading" color="transparent" elevation="0" class="mb-5 d-flex align-center justify-center" height="400px">
					<v-progress-circular color="primary" indeterminate></v-progress-circular>
				</v-card>
				<template v-else>
					<p v-if="!hasItems && hasSearch">
						{{ $t('service-requests.step2.no-results') }}
					</p>
					<p v-if="!hasItems && !hasSearch">{{ $t('service-requests.step2.no-data') }}</p>
					<template v-for="category in catalogFiltered">
						<v-list v-if="category.abbreviation !== OFFERS && Object.keys(category.items).length > 0" :key="category.id" dense>
							<v-chip :color="category.color" label text-color="white" class="category-name" :disabled="true"><span v-html="category.name"></span></v-chip>
							<v-divider :color="category.color" class="category-divider"></v-divider>
							<v-list-tile v-for="item in category.items" :key="item.id">
								<v-list-tile-action>
									<w-checkbox
										:disabled="catalogServiceIds.includes(item.id)"
										:value="selected_services.includes(item.id)"
										@input="toggleService(item.id, $event)"
									></w-checkbox>
								</v-list-tile-action>
								<v-btn flat icon color="blue" small class="m-0 hidden-sm-and-down" @click="moreAboutService(item)">
									<v-icon>info</v-icon>
								</v-btn>
								<v-list-tile-content class="pointer hidden-sm-and-down" @click="moreAboutService(item)">
									<v-list-tile-title v-html="item.displayName"></v-list-tile-title>
								</v-list-tile-content>
								<v-list-tile-content class="pointer hidden-md-and-up" @click="toggleService(item.id)">
									<v-list-tile-title v-html="item.displayName"></v-list-tile-title>
								</v-list-tile-content>
							</v-list-tile>
						</v-list>
					</template>
					<template v-for="category in catalogFiltered">
						<v-list v-if="category.abbreviation == OFFERS && Object.keys(category.items).length > 0" :key="category.id" dense>
							<v-chip :color="category.color" label text-color="white" class="category-name" :disabled="true"><span v-html="category.name"></span></v-chip>
							<v-divider :color="category.color" class="category-divider"></v-divider>
							<v-list-tile v-for="item in category.items" :key="item.id">
								<v-list-tile-action>
									<w-checkbox
										:disabled="catalogOfferIds.includes(item.id)"
										:value="selected_offers.includes(item.id)"
										@input="toggleOffer(item.id, $event)"
									></w-checkbox>
								</v-list-tile-action>
								<v-btn flat icon color="blue" small class="m-0 hidden-sm-and-down" @click="moreAboutOffer(item)">
									<v-icon>info</v-icon>
								</v-btn>
								<v-list-tile-content class="pointer hidden-sm-and-down" @click="moreAboutOffer(item)">
									<v-list-tile-title v-html="item.displayName"></v-list-tile-title>
								</v-list-tile-content>
								<v-list-tile-content class="pointer hidden-md-and-up" @click="toggleOffer(item.id)">
									<v-list-tile-title v-html="item.displayName"></v-list-tile-title>
								</v-list-tile-content>
							</v-list-tile>
						</v-list>
					</template>
				</template>
			</template>
			<template v-else-if="step == 2">
				<h2 class="title mt-3">{{ $t('service-requests.step2.title') }}</h2>
				<p class="body-1 mt-2 mb-4">{{ $t('service-requests.step2.description') }}</p>
				<w-layout v-if="step == 2" column align-start class="pb-4">
					<template v-for="category in selection">
						<template v-if="category.abbreviation !== OFFERS">
							<chips-with-label v-for="item in category.items" :key="item.id" :label-text="category.name" :label-color="category.color" class>
								{{ item.title }}
							</chips-with-label>
						</template>
					</template>
					<template v-for="category in selection">
						<template v-if="category.abbreviation == OFFERS">
							<chips-with-label v-for="item in category.items" :key="item.id" :label-text="category.name" :label-color="category.color" class>
								{{ item.name }}
							</chips-with-label>
						</template>
					</template>
				</w-layout>
			</template>
			<template v-else-if="step == 3">
				<h2 class="title mt-3">{{ $t('service-requests.step3.title') }}</h2>
				<template v-if="result">
					<p class="body-1 mt-2">{{ $t('service-requests.step3.success') }}</p>
					<v-icon color="primary" size="180">check_circle</v-icon>
				</template>
				<template v-else>
					<p class="body-1 mt-2">{{ $t('service-requests.step3.error') }}</p>
					<div class="overflow-hidden">
						<v-icon color="primary" size="180">close_circle</v-icon>
					</div>
				</template>
			</template>
		</w-flex>
		<w-flex shrink>
			<div v-if="step == 1 || step == 2 || (step == 3 && !result)" class="pt-4 pb-2 px-4">
				<template v-if="step == 1">
					<w-btn color="primary" :disabled="!hasSelection" @click="next">
						{{ $t('service-requests.step1.buttons.next') }}
					</w-btn>
					<w-btn flat @click="reset">{{ $t('service-requests.step1.buttons.cancel') }}</w-btn>
				</template>
				<template v-if="step == 2">
					<w-btn color="primary" :disabled="!hasSelection" :loading="loading" @click="send">
						{{ $t('service-requests.step2.buttons.next') }}
					</w-btn>
					<w-btn flat :disabled="loading" @click="step = 1">
						{{ $t('service-requests.step2.buttons.cancel') }}
					</w-btn>
				</template>
				<template v-if="step == 3">
					<w-btn color="primary" class="mx-0" @click="send">
						{{ $t('service-requests.step3.buttons.retry') }}
					</w-btn>
				</template>
			</div>
		</w-flex>
		<div v-if="showNavigationDrawer" class="v-overlay v-overlay--absolute v-overlay--active" @click="closeNavigationDrawer"></div>
		<w-navigation-drawer v-if="showNavigationDrawer" default-width="50%" max-width="100vw" mobile-break-point="720" absolute clipped resizable right  style="z-index: 9">
			<template v-slot:default="{ on }">
				<w-layout column fill-height>
					<router-view :services-selected="selected_services" :offers-selected="selected_offers" v-on="on"></router-view>
				</w-layout>
			</template>
		</w-navigation-drawer>
	</w-layout>
</template>
<script>
import ServiceRequestsModuleGuard from '@/mixins/ModulesGuards/ServiceRequests/ServiceRequestsModuleGuard'
export default {
	name: 'ACatalog',
	components: {
		'chips-with-label': () => ({ component: import('@/components/Commons/Chips/ChipsWithLabel') })
	},
	mixins: [ServiceRequestsModuleGuard],
	props: {
		filters: {
			default: () => ({
				categories: []
			}),
			required: true,
			type: Object
		},
		catalogOfferIds: {
			required: true,
			type: Array
		},
		catalogServiceIds: {
			required: true,
			type: Array
		}
	},
	data: function () {
		return {
			catalogCategories: [],
			catalogServices: [],
			catalogOffers: [],
			search: '',
			loading: false,
			step: 1,
			fetching: false,
			result: false,
			selected_services: [],
			selected_offers: [],
			queryParameters: {
				categories: 'categories',
				catalogOffer: 'catalog-offer',
				catalogService: 'catalog-service'
			}
		}
	},
	computed: {
		OFFERS: function () {
			return 'OFFERS'
		},
		catalog: function () {
			let catalog = this.cloneObject(this.catalogCategories)
			catalog.map(category => {
				category.items = {}
				this.catalogServices.forEach(service => {
					service.displayName = service.title
					service.originalDisplayName = service.title
					if (service.category.id == category.id) {
						category.items[service.title] = service
					}
				})
				return category
			})
			catalog.push({
				abbreviation: this.OFFERS,
				color: '#000000',
				id: 0,
				name: this.$t('service-requests.offers'),
				items: (() => {
					let t = {}
					this.catalogOffers.forEach(offers => {
						offers.displayName = offers.name
						offers.originalDisplayName = offers.name
						t[offers.name] = offers
					})
					return t
				})()
			})
			return catalog
		},
		catalogFiltered: function () {
			let search = this.search == null ? '' : this.search.trim().toLowerCase()
			if (search.length <= 1 && this.filters.categories.length == 0) {
				return this.catalog
			}
			let data = this.cloneObject(this.catalog)
			let categoriesWithSearchInName = data.filter(category => category.name.toLowerCase().includes(search)).map(category => category.id)
			data = data.map(category => {
				if (categoriesWithSearchInName.includes(category.id)) {
					return category
				}
				let r = {}
				Object.values(category.items)
					.filter(item => item.displayName.toLowerCase().includes(search))
					.forEach(item => {
						r[item.originalDisplayName] = item
					})
				category.items = r
				return category
			})
			if (this.filters.categories.length >= 1) {
				data = data.filter(category => this.filters.categories.includes(category.id) || category.abbreviation == this.OFFERS)
			}
			data = data.map(category => {
				const searchedTextRegEx = new RegExp(search.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&'), 'gi')
				category.name = category.name.replace(searchedTextRegEx, '<b>$&</b>')
				let r = {}
				Object.values(category.items)
					.map(item => {
						const searchedTextRegEx = new RegExp(search.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&'), 'gi')
						item.displayName = item.displayName.replace(searchedTextRegEx, '<b>$&</b>')
						return item
					})
					.forEach(item => {
						r[item.originalDisplayName] = item
					})
				category.items = r
				return category
			})
			return data
		},
		hasItems: function () {
			let has = false
			this.catalogFiltered.map(category => {
				if (Object.keys(category.items).length > 0) {
					has = true
				}
			})
			return has
		},
		hasSelection: function () {
			return this.selected_offers.length + this.selected_services.length >= 1
		},
		hasSearch: function () {
			return (this.search == null ? '' : this.search.trim().toLowerCase()).length > 0
		},
		showNavigationDrawer: function () {
			return this.$route.name.includes('service-detail') || this.$route.name.includes('offer-detail')
		},
		selection: function () {
			let r = this.cloneObject(this.catalog)
			r.map(category => {
				let r = {}
				Object.values(category.items).forEach(item => {
					if (category.abbreviation == this.OFFERS ? this.selected_offers.includes(item.id) : this.selected_services.includes(item.id)) {
						r[item.displayName] = item
					}
				})
				category.items = r
				return category
			})
			return r
		}
	},
	watch: {
		accountingFirmId: {
			handler: 'initCatalogServiceAndOffer'
		},
		filters: {
			deep: true,
			immediate: true,
			handler: 'updateFilters'
		},
		catalogOfferIds: {
			handler: 'updateFilters'
		},
		catalogServiceIds: {
			handler: 'updateFilters'
		}
	},
	mounted: function () {
		this.initCatalogServiceAndOffer()
	},
	methods: {
		getModuleEventsActionsMapping: function () {
			return [
				{
					event: this.events.SERVICE_SELECT,
					action: serviceId => {
						this.toggleService(serviceId, true)
					}
				},
				{
					event: this.events.SERVICE_UNSELECT,
					action: serviceId => {
						this.toggleService(serviceId, false)
					}
				},
				{
					event: this.events.OFFER_SELECT,
					action: offerId => {
						this.toggleOffer(offerId, true)
					}
				},
				{
					event: this.events.OFFER_UNSELECT,
					action: offerId => {
						this.toggleOffer(offerId, false)
					}
				}
			]
		},
		closeNavigationDrawer: function () {
			let queryParams = { name: 'service-requests-catalog', query: {} }
			if (this.filters.categories.length >= 1) {
				queryParams.query[this.queryParameters.categories] = this.filters.categories.map(category => category.toString(16)).join(',')
			}
			if (this.catalogServiceIds.length >= 1) {
				queryParams.query[this.queryParameters.catalogService] = this.catalogServiceIds.map(category => category.toString(16)).join(',')
			}
			if (this.catalogOfferIds.length >= 1) {
				queryParams.query[this.queryParameters.catalogOffer] = this.catalogOfferIds.map(category => category.toString(16)).join(',')
			}
			this.appService.goTo(queryParams)
		},
		fetchCategories: function () {
			return this.service
				.listCategories(this.accountingFirmId)
				.promise.then(data => {
					this.catalogCategories = Array.isArray(data) ? data : Object.values(data)
				})
				.catch(e => {
					throw e
				})
		},
		fetchServices: async function () {
			return this.service
				.listCatalogServices(
					this.accountingFirmId,
					(() => {
						let filter = {}
						if (this.filters.categories.length >= 1) {
							filter['categories'] = this.filters.categories
						}
						return filter
					})()
				)
				.promise.then(data => {
					data.map(catalogService => {
						catalogService.title = `${catalogService.unique_reference} - ${catalogService.name}`
						return catalogService
					})
					this.catalogServices = Array.isArray(data) ? data : Object.values(data)
				})
				.catch(e => {
					throw e
				})
		},
		fetchOffers: async function () {
			let filter = {}
			if (!this.filters.categories.includes(0) && this.filters.categories.length >= 1) {
				filter['categories'] = this.filters.categories
			}
			return this.service
				.listCatalogOffers(this.accountingFirmId, filter)
				.promise.then(data => (this.catalogOffers = Array.isArray(data) ? data : Object.values(data)))
				.catch(e => {
					throw e
				})
		},
		toggleService: function (serviceId, value = null) {
			if (this.catalogServiceIds.includes(serviceId)) {
				this.selected_services.push(serviceId)
				return
			}
			if (value == null) {
				value = !this.selected_services.includes(serviceId)
			}
			if (value) {
				this.selected_services.push(serviceId)
			} else {
				let index = this.selected_services.findIndex(cs => cs == serviceId)
				if (index >= 0) {
					this.selected_services.splice(index, 1)
				}
			}
		},
		toggleOffer: function (offerId, value = null) {
			if (value == null) {
				value = !this.selected_offers.includes(offerId)
			}
			if (value) {
				this.selected_offers.push(offerId)
			} else {
				let index = this.selected_offers.findIndex(cs => cs == offerId)
				if (index >= 0) {
					this.selected_offers.splice(index, 1)
				}
			}
		},
		updateFilters: function () {
			if (this.catalogOfferIds.length + this.catalogServiceIds.length != 0 && !this.fetching) {
				this.catalog.forEach(category => {
					category.items?.forEach(item => {
						if (category.abbreviation == this.OFFERS && this.catalogOfferIds.includes(item.id) && !this.selected_offers.includes(item.id)) {
							this.selected_offers.push(item.id)
						} else if (category.abbreviation != this.OFFERS && this.catalogServiceIds.includes(item.id) && !this.selected_services.includes(item.id)) {
							this.selected_services.push(item.id)
						}
					})
				})
			}
		},
		initCatalogServiceAndOffer: async function () {
			if (this.fetching) {
				return Promise.resolve()
			}

			this.fetching = true
			this.loading = true
			this.catalogCategories.splice(0, this.catalogCategories.length)
			this.catalogServices.splice(0, this.catalogServices.length)

			return Promise.all([this.fetchCategories(), this.fetchOffers(), this.fetchServices()])
				.then(() => {
					this.updateFilters()
					this.loading = false
				})
				.catch(err => {
					this.appEventBus.emit(this.appEvents.SNACKBAR_ERROR, this.$t('service-requests.error.fetch'))
					throw err
				})
				.finally(() => {
					this.fetching = false
				})
		},
		moreAboutService: function (service) {
			let queryParams = { query: {} }
			if (this.filters.categories.length >= 1) {
				queryParams.query[this.queryParameters.categories] = this.filters.categories.map(c => c.toString(16)).join(',')
			}
			if (this.catalogServiceIds.length >= 1) {
				queryParams.query[this.queryParameters.catalogService] = this.catalogServiceIds.map(c => c.toString(16)).join(',')
			}
			if (this.catalogOfferIds.length >= 1) {
				queryParams.query[this.queryParameters.catalogOffer] = this.catalogOfferIds.map(c => c.toString(16)).join(',')
			}
			this.service.selectCatalogService(this.accountingFirmId, this.vendorId, service.id, queryParams)
		},
		moreAboutOffer: function (offer) {
			let queryParams = { query: {} }
			if (this.filters.categories.length >= 1) {
				queryParams.query['categories'] = this.filters.categories.map(c => c.toString(16)).join(',')
			}
			if (this.catalogServiceIds.length >= 1) {
				queryParams.query['catalog-service'] = this.catalogServiceIds.map(c => c.toString(16)).join(',')
			}
			if (this.catalogOfferIds.length >= 1) {
				queryParams.query['catalog-offer'] = this.catalogOfferIds.map(c => c.toString(16)).join(',')
			}
			this.service.selectCatalogOffer(this.accountingFirmId, this.vendorId, offer.id, queryParams)
		},
		next: function () {
			if (this.hasSelection) {
				this.step++
			}
		},
		reset: function () {
			if (this.hasSelection) {
				this.loading = true
				this.selected_offers = []
				this.selected_services = []
				this.$forceUpdate()
				setTimeout(() => {
					this.loading = false
				}, 350)
			}
		},
		send: async function () {
			this.loading = true
			this.step = 2
			await new Promise(resolve => setTimeout(resolve, 500))
			try {
				await this.service.createServiceRequest(this.accountingFirmId, this.vendorId, {
					catalog_offer_ids: this.selected_offers,
					catalog_service_ids: this.selected_services
				}).promise
				this.appEventBus.emit(this.appEvents.SNACKBAR_SUCCESS, this.$t('service-requests.success.service_request_created'))
				this.result = true
			} catch (err) {
				this.appEventBus.emit(this.appEvents.SNACKBAR_ERROR, this.$t('service-requests.error.service_request_not_created'))
				this.result = false
			}
			this.loading = false
			this.step = 3
		}
	}
}
</script>
<style scoped>
.v-stepper {
	box-shadow: none;
}
.v-list .category-divider {
	height: 6px;
	max-height: 6px;
	border: 0;
	position: relative;
	top: -2px;
}
.v-list .category-name {
	margin-left: 0;
	margin-bottom: 0;
}
</style>
